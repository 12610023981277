 <template>
  <div style="height:100%">
    <div style="padding: 20px;height: 800px">
      <!-- 搜素框 -->
      <el-form :inline="true" :model="params" style="width:80%;padding-top:20px;margin-bottom:20px;background-color:#fff;border-radius:8px" size="small">

        <el-form-item label="师傅名称" style="margin-left: 30px">
          <el-select filterable  v-model="params.masterId" placeholder="请选择师傅名称">
            <el-option v-for="item in masterList" :key="item.id" :label="(item.userName).substring(item.userName.indexOf('('), item.userName.indexOf(','))" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="区域">
          <el-select filterable v-model="params.regionId" placeholder="请选择区域">

            <el-option v-for="item in regionList"
                       :key="item.id"
                       :label="item.regionName" :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="品牌">
          <el-select filterable v-model="params.brandId" placeholder="请选择品牌">

            <el-option v-for="item in brandList"
                       :key="item.id"
                       :label="item.brandName" :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="时间范围">
          <el-date-picker v-model="params.startTime" type="datetime" placeholder="开始时间" value-format="yyyy-MM-dd HH:mm:ss" style="width: 180px;"></el-date-picker>
          <el-date-picker v-model="params.endTime" type="datetime" placeholder="结束时间" value-format="yyyy-MM-dd HH:mm:ss" style="margin-left:5px;width: 180px;"></el-date-picker>
        </el-form-item>
        <el-form-item style="margin-left: 40%">
          <el-button @click="checkData" type="primary" size="mini">查询</el-button>
          <el-button type="info" @click="reset" size="mini">重置</el-button>
        </el-form-item>
      </el-form>
      <!-- 表格 -->
      <div style="height: 82%; width: 80%;background-color: #fff; border-radius: 8px; display: flex; justify-content: center; align-items: center;">
        <div style="width: 100%; height: 80%; margin-left: 20%;margin-right: 20%">
          <!-- 导出按钮可以取消注释，启用功能 -->
          <!-- <el-button type="success" size="small" style="float: right; margin-top: 20px; margin-bottom: 20px;" @click="toExcel">导出Excel</el-button> -->

          <el-table :data="tableData" stripe height="100%" size="big"
                    :header-cell-style="{'background-color':'#84aace4a','color':'#343333','text-align':'center'}"
                    :cell-style="{'text-align':'center'}">
            <el-table-column prop="username" label="师傅姓名" width="130px"></el-table-column>
            <el-table-column prop="startTime" label="查询起始时间" :formatter="dateStartFormat" width="220px"></el-table-column>
            <el-table-column prop="endTime" label="查询结束时间" :formatter="dateEndFormat" width="220px"></el-table-column>
            <el-table-column prop="completeRate" label="完工率(%)" width="120px"></el-table-column>
          </el-table>

          <!--<div style="padding-top: 20px; height: 40px; background-color: #fff; width: 100%; border-radius: 8px; text-align: right;">
            <el-pagination background :page-sizes="sizes" layout="total, sizes, prev, pager, next" :total="total"
                           @current-change="handleCurrentChange" @size-change="sizeChange"></el-pagination>
          </div>-->
        </div>
      </div>

    </div>

  </div>
</template>
<script>
import moment from 'moment'
export default {
  data() {
    return {
      tableData: [],
      masterList:[],
      regionList: [],
      brandList: [],
      params: {
        endTime: "",
        startTime: "",
        regionId: "",
        masterId: "",
        brandId:"",
        page: 1,
        size: 10,
      },
      total: 0,
      sizes: [10, 15],
    };
  },
  created() {
    this.queryCompleteRateList()  ;
    this.queryMasterList();
    this.queryRegionList();
    this.queryDeviceBrandDrown();
  },
  methods: {
    queryMasterList(){//所属师傅下拉（包含暂不接单的师傅）
      this.axios({
        method: "get",
        url: "/v1/web/user-info/select-master-drown"
      }).then((res) => {
        this.masterList = []
        let obj = {}
        obj.id = 0;
        obj.userName = "全部("
        this.masterList.push(obj)
        this.masterList.push(... res.data.data.list)
      });
    },
    queryDeviceBrandDrown() {
				this.axios({
					method: 'get',
					url: '/v1/web/device-brand/select-device-brand',
				}).then((res) => {
					this.brandList = res.data.data.list;
				});
			},
    queryRegionList() {
				//所属区域下拉
				this.axios({
					method: 'get',
					url: '/v1/web/region-manage/select-region-drop-down',
					params: this.params,
				}).then((res) => {
					// this.regionList=[]
					// let obj = {}
					// obj.id = 0;
					// obj.regionName = "全部"
					// this.regionList.push(obj)
          console.log(...res.data.data.list)
					this.regionList.push(...res.data.data.list);
				});
			},
    queryCompleteRateList(){//获取列表信息
      this.axios({
        method: "post",
        url: "/v1/web/order-info/complete-order-rate",
        params: this.params,
      }).then((res) => {
        this.tableData = res.data.data.completeRateVOList;
        this.total = res.data.data.total;
      });
    },
    toExcel(){//获取列表信息
      this.axios({
        method: "get",
        url: "/v1/web/order-info/export-settlement",
        params: this.params,
        responseType: "blob"
      }).then((res) => {
        let filename=res.headers['content-disposition']
        const link=document.createElement("a");
        let blob=new Blob([res.data], { type: "application/octet-stream" }); //类型excel
        link.style.display="none";
        link.href=URL.createObjectURL(blob);
        link.setAttribute("download", decodeURI(filename.substring(filename.indexOf("=")+1)));
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    dateStartFormat:function(row){
      if(row.startTime !== null && row.startTime !== undefined){
        return moment(row.startTime).format("YYYY-MM-DD HH:mm:ss")
      }
    },
    dateEndFormat:function(row){
      if(row.endTime !== null && row.endTime !== undefined){
        return moment(row.endTime).format("YYYY-MM-DD HH:mm:ss")
      }
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.queryCompleteRateList();
    },
    sizeChange(val) {
      this.params.size = val;
      this.params.page = 1;
      this.queryCompleteRateList();
    },
    checkData() {
      this.queryCompleteRateList();
    },
    reset() {
      this.params = { page: 1, size: 10 };
      this.queryCompleteRateList();
      this.params = {};
    },
  },
};
</script>

<style scoped>

</style>
